import React, { useEffect } from 'react'

import { ToggleSwitch } from '@toasttab/buffet-pui-toggle-switches'

import { trackEvent } from '../../utils/tracking'

interface EmailSubscriptionToggleProps {
  isActive: boolean
  onChange: (isActive: boolean) => void
}

export const EmailSubscriptionToggle = ({
  isActive,
  onChange
}: EmailSubscriptionToggleProps) => {
  useEffect(() => {
    trackEvent({ name: 'Email Subscription Toggle' })
  }, [])
  return (
    <div className='w-full flex flex-col justify-center border bg-gray-0 rounded-lg p-4'>
      <div className='flex justify-between items-center mb-2'>
        <p className='type-default font-semibold text-default mr-4'>
          Sign up for special offers
        </p>
        <ToggleSwitch
          size='xxs'
          isActive={isActive}
          onChange={() => onChange(!isActive)}
          aria-label='Sign up for special offers'
        />
      </div>
      <p className='type-caption text-secondary'>
        Send me emails with special offers and discounts from this restaurant or
        restaurant group. You can opt-out at any time.
      </p>
    </div>
  )
}
