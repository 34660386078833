import * as React from 'react'

import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider
} from 'react-router-dom'
import { ReceiptLookup } from '@local/receipt-lookup'

const RECEIPT_LOOKUP_ROUTE = '/receipt-lookup'

export const routes = [
  {
    element: <Outlet />,
    children: [
      {
        path: RECEIPT_LOOKUP_ROUTE,
        element: <ReceiptLookup />
      },
      {
        path: '*',
        element: <Navigate to={RECEIPT_LOOKUP_ROUTE} replace />
      }
    ]
  }
]

const router = createBrowserRouter(routes, {
  basename: '/fundraising'
})

export const App = () => {
  return <RouterProvider router={router} />
}
