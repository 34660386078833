import React, { PropsWithChildren } from 'react'
import classNames from 'classnames'
import { BodyBackgroundColor } from '../shared/BodyBackgroundColor'

export interface MainLayoutProps {
  maxWidth?: '2xl' | '6xl' | 'none'
}

export const MainLayout: React.FC<PropsWithChildren<MainLayoutProps>> = ({
  maxWidth = '2xl',
  children
}) => (
  <>
    <BodyBackgroundColor colorVar={'color-gray-30'} />
    <div style={{ minHeight: '100vh' }}>
      <div
        className={
          'w-full h-full flex justify-center px-6 py-4 md:p-8 transition-[padding]'
        }
        style={{ minHeight: '100vh' }}
      >
        <main
          className={classNames('w-full', {
            'max-w-6xl': maxWidth === '6xl',
            'max-w-2xl': maxWidth === '2xl'
          })}
        >
          {children}
        </main>
      </div>
    </div>
  </>
)
