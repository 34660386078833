import React from 'react'

import { PhoneInput } from '@toasttab/buffet-pui-phone-input'

import { useLoyaltyContext } from '../../context/LoyaltyContext'

export const TelephoneInput = ({ disabled, value, onChange }) => {
  const {
    restaurant: { locale }
  } = useLoyaltyContext()
  const [phoneData, setPhoneData] = React.useState({
    nationalNumber: value,
    countryIsoCode: locale.split('-')[1]
  })

  const onChangeInput = (phoneNumber) => {
    setPhoneData(phoneNumber)
    onChange(phoneNumber)
  }

  return (
    <PhoneInput
      onChange={onChangeInput}
      disabled={disabled}
      name='phone'
      disableCountry
      value={phoneData}
    />
  )
}
