import * as React from 'react'
import Router from '../components/Router/Router'
import { ErrorBoundary } from '../components/ErrorBoundary/ErrorBoundary'
import { ErrorPage500 } from '@toasttab/buffet-pui-error-pages'
import { HelmetProvider } from 'react-helmet-async'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { useEffect, useMemo } from 'react'

export const App: React.FC = () => {
  const queryClient = useMemo<QueryClient>(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: true,
            refetchOnWindowFocus: false,
            refetchOnMount: true,
            refetchOnReconnect: false
          }
        }
      }),
    []
  )

  useEffect(() => {
    document.title = 'Toast'
  }, [])

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools />
        <ErrorBoundary fallback={<ErrorPage500 />}>
          <HelmetProvider>
            <Router />
          </HelmetProvider>
        </ErrorBoundary>
      </QueryClientProvider>

      {/* Need this div for modals to render correctly */}
      <div id={'banquetPortalsContainer'} />
    </>
  )
}
