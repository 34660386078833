import { registerApplication } from 'single-spa'
import { routeMatcher } from '@toasttab/banquet-match-utils'
import { importSpa } from '../utils'

export const fundraising = () => {
  // --------------------------------------
  // Define layout spas
  // --------------------------------------

  registerApplication({
    name: 'fundraising-public-spa',
    activeWhen: ({ pathname }) =>
      Boolean(routeMatcher(['/fundraising', '/fundraising/*'])(pathname)),
    app: importSpa('fundraising-public-spa')
  })
}
