import React from 'react'
import { Skeleton } from '@toasttab/buffet-pui-loading-indicators'

export const OrderSummaryLoading = React.memo(() => (
  <div className={'space-y-5'}>
    <Skeleton className={'h-6'} />
    <Skeleton className={'h-6'} />
    <Skeleton className={'h-6'} />
  </div>
))
