import axios, { AxiosPromise, AxiosResponse } from 'axios'
import {
  ToastEnvironment,
  getCurrentEnvironment
} from '@toasttab/current-environment'
import {
  G2_FRONTEND_URL_DEV,
  G2_FRONTEND_URL_PREPROD,
  G2_FRONTEND_URL_PROD,
  G2_FRONTEND_URL_SANDBOX,
  GUEST_FEEDBACK_GET_URL,
  GUEST_FEEDBACK_PUT_URL,
  GUEST_FEEDBACK_ORDER_ITEMS_LIST_URL,
  GUEST_FEEDBACK_ORDER_ITEMS_PUT_URL,
  GUEST_FEEDBACK_POST_URL
} from './constants'
import {
  GuestFeedbackPayload,
  GuestFeedbackCombinedResponse,
  OrderItem,
  GenericGuestFeedbackPayload,
  GenericGuestFeedbackResponse
} from '../guestFeedbackDetailsContext'
import { GuestFeedbackUrlData, OrderItemUrlData } from '../types'
import { useMockContext } from '../../../__mocks__/MockProvider'

const getGuestFeedback = ({
  shortUrl,
  restaurantGuid,
  checkGuid,
  orderGuid,
  orderPaymentGuid,
  search,
  showOrderItemFeedback = false
}: GuestFeedbackUrlData & {
  showOrderItemFeedback?: boolean
}): Promise<AxiosResponse<GuestFeedbackCombinedResponse>> => {
  const url = GUEST_FEEDBACK_GET_URL.replace('{SHORT_URL}', shortUrl)
    .replace('{RESTAURANT_GUID}', restaurantGuid)
    .replace('{CHECK_GUID}', checkGuid)
    .replace('{ORDER_GUID}', orderGuid)
    .replace('{ORDER_PAYMENT_GUID}', orderPaymentGuid)
    .replace('{SEARCH}', search)

  const sendDataRequests = [axios.get(url)]
  if (showOrderItemFeedback)
    sendDataRequests.push(
      getOrderItems({
        restaurantGuid,
        orderGuid,
        checkGuid,
        orderPaymentGuid
      })
    )

  return axios
    .all(sendDataRequests)
    .then(([feedbackResponse, orderItemsResponse]) => {
      return {
        ...feedbackResponse,
        data: {
          ...feedbackResponse.data,
          orderItems: orderItemsResponse?.data || []
        }
      }
    })
}

const getOrderItems = ({
  restaurantGuid,
  orderGuid,
  checkGuid,
  orderPaymentGuid
}: OrderItemUrlData): AxiosPromise<OrderItem[]> => {
  const baseUrl = getG2FrontendUrl()
  const orderItemsUrl = GUEST_FEEDBACK_ORDER_ITEMS_LIST_URL.replace(
    '{RESTAURANTGUID}',
    restaurantGuid
  )
    .replace('{ORDERGUID}', orderGuid)
    .replace('{CHECKGUID}', checkGuid)
    .replace('{PAYMENTGUID}', orderPaymentGuid)

  const url = baseUrl + orderItemsUrl
  return axios.post(url)
}

export const getG2FrontendUrl = () => {
  const env = getCurrentEnvironment()

  if (env === ToastEnvironment.DEV) {
    return G2_FRONTEND_URL_DEV
  } else if (env === ToastEnvironment.SANDBOX) {
    return G2_FRONTEND_URL_SANDBOX
  } else if (env === ToastEnvironment.PREPROD) {
    return G2_FRONTEND_URL_PREPROD
  } else {
    return G2_FRONTEND_URL_PROD // prod as default
  }
}

const putGuestFeedback = (
  feedbackGuid: string,
  data: GuestFeedbackPayload
): Promise<AxiosResponse<void>[]> => {
  const baseUrl = getG2FrontendUrl()
  const url =
    baseUrl + GUEST_FEEDBACK_PUT_URL.replace('{FEEDBACK_GUID}', feedbackGuid)

  const sendDataRequests = [axios.put(url, data)]
  if (Object.keys(data.feedbackItems).length > 0)
    sendDataRequests.push(postItemOrderFeedback(data))

  return axios.all(sendDataRequests)
}

const postItemOrderFeedback = (data: GuestFeedbackPayload) => {
  const baseUrl = getG2FrontendUrl()
  const url = baseUrl + GUEST_FEEDBACK_ORDER_ITEMS_PUT_URL

  const orderItems = Object.entries(data.feedbackItems).map(
    ([itemGuid, rating]) => {
      const item = data.orderItems.find((item) => item.itemGuid === itemGuid)

      return {
        restaurantGUID: data.restaurantGUID,
        orderGUID: data.orderGUID,
        checkGUID: data.checkGUID,
        orderPaymentGUID: data.orderPaymentGUID,
        feedbackSource: data.feedbackSource,
        feedbackReasons: data.feedbackReasons,
        contactInformation: data.contactInformation,
        complete: data.complete,
        feedbackRating: rating,
        feedbackRawRating: item?.feedbackRawRating,
        itemDetails: {
          itemGuid,
          itemGroupGuid: item?.itemGroupGuid,
          displayName: item?.displayName
        }
      }
    }
  )

  return axios.post(url, orderItems)
}

export const postGuestFeedback = (
  restaurantGuid: string,
  payload: GenericGuestFeedbackPayload
) => {
  const baseUrl = getG2FrontendUrl()
  const url =
    baseUrl +
    GUEST_FEEDBACK_POST_URL.replace('{RESTAURANT_GUID}', restaurantGuid)

  if (!restaurantGuid) {
    throw new Error('restaurantGuid is required')
  }

  return axios.post<
    GenericGuestFeedbackResponse,
    AxiosResponse<GenericGuestFeedbackResponse>,
    GenericGuestFeedbackPayload
  >(url, payload)
}

export const api = {
  getGuestFeedback,
  postGuestFeedback,
  putGuestFeedback
}

export const useApi = () => {
  const { mockGetGuestFeedback, mockPostGuestFeedback, mockPutGuestFeedback } =
    useMockContext()

  return {
    ...api,
    getGuestFeedback: mockGetGuestFeedback || api.getGuestFeedback,
    postGuestFeedback: mockPostGuestFeedback || api.postGuestFeedback,
    putGuestFeedback: mockPutGuestFeedback || api.putGuestFeedback
  }
}
