import * as React from 'react'

import {
  ErrorContainer,
  ErrorHeading,
  ErrorSubheading
} from '@toasttab/buffet-pui-error-pages'

import {
  Permissions403DesktopIllustration,
  Permissions403MobileIllustration
} from '@toasttab/buffet-pui-illustrations'

import { t } from '@local/translations'

/**
 * ReceiptLookup component
 */
export const ReceiptLookup = () => {
  return (
    <ErrorContainer>
      <div data-testid='generic-error-page'>
        <div
          className='hidden md:block visible mx-auto'
          style={{
            maxWidth: 600
          }}
        >
          <Permissions403DesktopIllustration />
        </div>
        <div
          className='block md:hidden visible mx-auto'
          style={{
            maxWidth: 250
          }}
        >
          <Permissions403MobileIllustration />
        </div>
      </div>
      <div>
        <ErrorHeading>{t('fundraising.errorPage.title')}</ErrorHeading>
        <ErrorSubheading>{t('fundraising.errorPage.message')}</ErrorSubheading>
      </div>
    </ErrorContainer>
  )
}
