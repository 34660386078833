import React from 'react'

import parsePhoneNumber, { isValidPhoneNumber } from 'libphonenumber-js'

import { Alert } from '@toasttab/buffet-pui-alerts'
import { Button } from '@toasttab/buffet-pui-buttons'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { Modal } from '@toasttab/buffet-pui-modal'
import { isValidEmail } from '@toasttab/email-validation'

import { Dropdown } from './Dropdown'
import { EmailInput } from './EmailInput'
import { EmailSubscriptionToggle } from './EmailSubscriptionToggle'
import { NumberInput } from './NumberInput'
import { SmsSubscriptionToggle } from './SmsSubscriptionToggle'
import { TelephoneInput } from './TelephoneInput'
import { Terms } from './Terms'
import { TextInput } from './TextInput'
import { useLoyaltyContext } from '../../context/LoyaltyContext'

const MONTH_OPTIONS = [
  { value: 1, label: 'January', days: 31 },
  { value: 2, label: 'February', days: 29 },
  { value: 3, label: 'March', days: 31 },
  { value: 4, label: 'April', days: 30 },
  { value: 5, label: 'May', days: 31 },
  { value: 6, label: 'June', days: 30 },
  { value: 7, label: 'July', days: 31 },
  { value: 8, label: 'August', days: 31 },
  { value: 9, label: 'September', days: 30 },
  { value: 10, label: 'October', days: 31 },
  { value: 11, label: 'November', days: 30 },
  { value: 12, label: 'December', days: 31 }
]

export const UserDetailsModal = () => {
  const {
    rewardsConfig,
    userDetailsModal,
    closeUserDetailsModal,
    onChangeUserDetailsModal,
    submitUserDetailsModal,
    creditCardModal,
    showQrCode,
    smsMarketingAccountEnabled,
    setSubscribeToSmsMarketing,
    subscribeToSmsMarketing,
    isInternationalRestaurant,
    subscribeToEmailMarketing,
    setSubscribeToEmailMarketing,
    restaurant: { locale }
  } = useLoyaltyContext()

  if (!userDetailsModal.open || creditCardModal.open || showQrCode) {
    return null
  }

  const {
    firstNameSubmitted,
    firstName,
    lastNameSubmitted,
    lastName,
    emailSubmitted,
    email,
    phoneNumberSubmitted,
    phoneNumber,
    birthdayMonthSubmitted,
    birthdayMonth,
    birthdayDaySubmitted,
    birthdayDay,
    loading,
    errorMessage
  } = userDetailsModal

  const showBirthdayCopy =
    rewardsConfig?.birthdayRewardsEnabled &&
    !birthdayMonthSubmitted &&
    !birthdayDaySubmitted

  const onChangePhoneNumber = (value) => {
    if (isValidPhoneNumber(value.nationalNumber, value.countryIsoCode)) {
      const parsedPhoneNumber = parsePhoneNumber(
        value.nationalNumber,
        value.countryIsoCode
      )

      onChangeUserDetailsModal('phoneNumber')(parsedPhoneNumber.nationalNumber)
      onChangeUserDetailsModal('phoneNumberCountryCode')(
        parsedPhoneNumber.countryCallingCode
      )
    }
  }

  return (
    <Modal
      appElement={document.getElementById('root')}
      isOpen={true}
      onRequestClose={loading ? undefined : closeUserDetailsModal}
      size={Modal.Size.md}
      testId='user-details-modal'
    >
      <Modal.Header>
        <h4 className='type-headline-4 text-default font-bold pr-2'>
          {showBirthdayCopy ? 'Share your birthday' : 'Complete your profile'}
        </h4>
      </Modal.Header>
      <Modal.Body>
        <div className='relative' style={{ minHeight: '20rem' }}>
          {loading && (
            <div
              role='progressbar'
              aria-label='loading icon'
              aria-valuetext='Loading'
              className='absolute top-0 left-0 right-0 bottom-0 bg-lighten-60 flex items-center justify-center'
            >
              <MerryGoRound size={MerryGoRound.Size.md} />
            </div>
          )}
          {errorMessage && (
            <Alert variant='error' className='w-full mb-4' showIcon>
              {errorMessage}
            </Alert>
          )}
          <p className='type-default text-default pb-6'>
            {showBirthdayCopy
              ? "We'll help you celebrate with a special reward. We may also send you additional promotions, and personalize your dine-in experience."
              : "Help us learn how to customize your guest experience! Share your birthday and we'll help you celebrate with a special reward. We may also send you additional promotions, and personalize your dine-in experience."}
          </p>

          {(!firstNameSubmitted || !lastNameSubmitted) && (
            <div className='pb-4'>
              <p className='type-default text-default font-bold pb-1'>Name</p>
              <div className='flex'>
                {!firstNameSubmitted && (
                  <div className={lastNameSubmitted ? 'w-full' : 'w-1/2 pr-1'}>
                    <TextInput
                      ariaLabel='First name'
                      placeholder='First Name'
                      disabled={loading}
                      value={firstName}
                      onChange={onChangeUserDetailsModal('firstName')}
                    />
                  </div>
                )}
                {!lastNameSubmitted && (
                  <div className={firstNameSubmitted ? 'w-full' : 'w-1/2 pl-1'}>
                    <TextInput
                      ariaLabel='Last name'
                      placeholder='Last Name'
                      disabled={loading}
                      value={lastName}
                      onChange={onChangeUserDetailsModal('lastName')}
                    />
                  </div>
                )}
              </div>
            </div>
          )}

          {(!birthdayMonthSubmitted || !birthdayDaySubmitted) && (
            <div className='pb-4'>
              <p className='type-default text-default pb-1'>
                <span className='font-bold'>Birthday</span>
              </p>
              <div className='flex'>
                <div className='w-1/2 pr-1'>
                  <Dropdown
                    testId='month-dropdown'
                    options={MONTH_OPTIONS}
                    disabled={loading}
                    value={birthdayMonth}
                    onChange={onChangeUserDetailsModal('birthdayMonth')}
                  />
                </div>
                <div className='w-1/2 pl-1'>
                  <NumberInput
                    ariaLabel='birthday day'
                    placeholder='Day'
                    disabled={loading}
                    invalid={
                      birthdayDay &&
                      birthdayMonth &&
                      !isValidBirthdayDay(birthdayDay, birthdayMonth)
                    }
                    value={birthdayDay}
                    onChange={onChangeUserDetailsModal('birthdayDay')}
                  />
                </div>
              </div>
            </div>
          )}

          {!emailSubmitted && (
            <div className='pb-4'>
              <p className='type-default text-default pb-1'>
                <span className='font-bold'>Email</span> (Required)
              </p>
              <EmailInput
                disabled={loading}
                value={email}
                onChange={onChangeUserDetailsModal('email')}
              />
            </div>
          )}

          {!phoneNumberSubmitted && (
            <div className='pb-4'>
              <p className='type-default text-default font-bold pb-1'>
                Phone Number
              </p>
              <TelephoneInput
                disabled={loading}
                value={phoneNumber}
                onChange={(value) => onChangePhoneNumber(value)}
              />
            </div>
          )}
          {!phoneNumberSubmitted && smsMarketingAccountEnabled && (
            <SmsSubscriptionToggle
              isActive={subscribeToSmsMarketing}
              onChange={setSubscribeToSmsMarketing}
            />
          )}
          {isInternationalRestaurant && (
            <EmailSubscriptionToggle
              isActive={subscribeToEmailMarketing}
              onChange={setSubscribeToEmailMarketing}
            />
          )}
          <Terms
            className='pt-2'
            isReceivingMarketing={true}
            showForEmail={isInternationalRestaurant}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          id='lw-click-user-modal-finish-later'
          variant={Button.Variant.secondary}
          disabled={loading}
          onClick={closeUserDetailsModal}
        >
          Finish later
        </Button>
        <Button
          id='lw-click-user-modal-submit'
          className='ml-4'
          disabled={loading || !isFormValid(userDetailsModal, locale)}
          onClick={submitUserDetailsModal}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const isFormValid = (userDetailsModal, locale) => {
  const {
    firstNameSubmitted,
    firstName,
    lastNameSubmitted,
    lastName,
    emailSubmitted,
    email,
    phoneNumberSubmitted,
    phoneNumber,
    birthdayMonthSubmitted,
    birthdayMonth,
    birthdayDaySubmitted,
    birthdayDay
  } = userDetailsModal

  if (
    !birthdayDaySubmitted &&
    !birthdayMonthSubmitted &&
    !isValidBirthdayDay(birthdayDay, birthdayMonth)
  ) {
    return false
  }
  if (!emailSubmitted && !isValidEmail(email)) {
    return false
  }
  if (
    !phoneNumberSubmitted &&
    phoneNumber &&
    !isValidPhoneNumber(phoneNumber, locale.split('-')[1])
  ) {
    return false
  }

  const submittedOrEmpty = [
    firstNameSubmitted || !firstName,
    lastNameSubmitted || !lastName,
    emailSubmitted || !email,
    phoneNumberSubmitted || !phoneNumber,
    birthdayMonthSubmitted || !birthdayMonth,
    birthdayDaySubmitted || !birthdayDay
  ]
  if (submittedOrEmpty.every((v) => v)) {
    return false
  }

  return true
}

const isValidBirthdayDay = (birthdayDay, birthdayMonth) => {
  const month = MONTH_OPTIONS.find((o) => o.value === birthdayMonth)
  if (!month) {
    return false
  }
  if (!birthdayDay || birthdayDay < 1 || birthdayDay > month.days) {
    return false
  }
  return true
}
