import React from 'react'
import { ThemedLayout } from '../shared/ThemedLayout/ThemedLayout'
import { EventPortalSection } from '../EventPortalView/EventPortalSection'
import { ThemedLayoutMainContent } from '../shared/ThemedLayout/ThemedLayoutMainContent'
import { Card } from '../shared/Card/Card'
import { ThemedLayoutSideContent } from '../shared/ThemedLayout/ThemedLayoutSideContent'
import { OrderSummaryLoading } from '../shared/Order/OrderSummaryLoading'
import { Skeleton } from '@toasttab/buffet-pui-loading-indicators'
import {
  CalendarTodayIcon,
  IconProps,
  PrepTimeIcon
} from '@toasttab/buffet-pui-icons'

export const EventPortalLoadingView = React.memo(() => {
  const renderDateTime = (IconComponent: React.FC<IconProps>) => (
    <span className={'flex items-center gap-2 w-1/4'}>
      <IconComponent
        accessibility={'decorative'}
        className={'black opacity-20'}
      />{' '}
      <Skeleton className={'w-3/4 h-5'} />
    </span>
  )

  const contentCard = (
    <Card className={'!bg-transparent'}>
      <EventPortalSection>
        <Skeleton className={'ml-4 md:ml-8 w-1/4 h-6'} />
        <div className={'px-4 md:px-8 pb-8 mt-6'}>
          <OrderSummaryLoading />
        </div>
      </EventPortalSection>
    </Card>
  )

  const sideCard = (
    <Card className={'px-4 py-6 space-y-4'} shadowDisabled>
      <Skeleton className={'w-2/3 h-6 my-1'} />
      <Skeleton className={'w-3/4 h-10 my-1'} />
    </Card>
  )

  const subHeader = (
    <div className={'flex flex-col gap-y-2'}>
      <div
        className={
          'flex flex-col md:flex-row flex-wrap gap-x-8 gap-y-2 mb-2 tsw-font-family text-color-ds order-last md:order-first'
        }
      >
        {renderDateTime(CalendarTodayIcon)}
        {renderDateTime(PrepTimeIcon)}
      </div>
      <Skeleton className={'w-3/4 h-11 mt-3 mb-1'} />
    </div>
  )

  return (
    <ThemedLayout
      subheader={<div className={'md:hidden mt-6'}>{subHeader}</div>}
    >
      <ThemedLayoutMainContent className={'mb-60 md:mb-0'}>
        <div className={'hidden md:block'}>{subHeader}</div>
        {contentCard}
        {contentCard}
      </ThemedLayoutMainContent>
      <ThemedLayoutSideContent>
        {sideCard}
        {sideCard}
      </ThemedLayoutSideContent>
    </ThemedLayout>
  )
})
