import React, { lazy, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import NotFoundView from '../NotFoundView/NotFoundView'
import { SessionExpiredModal } from '../Session/SessionExpiredModal'
import { EventPortalLoadingView } from '../LoadingView/EventPortalLoadingView'
import { MainLayout } from '../Main/MainLayout'

const LazyExpiredView = lazy(() => import('../ExpiredView'))
const LazyEstimateExpiredView = lazy(() => import('../EstimateExpiredView'))
const LazyEventRoute = lazy(() => import('../EventView/EventRoute'))
const LazyEventPortalRoute = React.lazy(
  () => import('../EventPortalView/EventPortalRoute')
)
const LazyInvoiceRoute = lazy(() => import('../InvoiceView/InvoiceRoute'))
const LazyLeadRoute = lazy(() => import('../LeadView/LeadRoute'))
const LazyLocationPickerView = lazy(
  () => import('../LocationPickerView/LocationPickerView')
)

// Required for any pages that make a call to the graphql API
const SessionWrapper = ({ children }: React.PropsWithChildren) => (
  <>
    <SessionExpiredModal />
    {children}
  </>
)

export const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path='invoice/expired'
          element={
            <Suspense fallback={null}>
              <LazyExpiredView />
            </Suspense>
          }
        />
        <Route
          path='invoice/estimateExpired'
          element={
            <Suspense fallback={null}>
              <LazyEstimateExpiredView />
            </Suspense>
          }
        />
        <Route
          path='invoice/lead'
          element={
            <Suspense fallback={null}>
              <LazyLeadRoute />
            </Suspense>
          }
        />
        <Route
          path='invoice/portal/:id'
          element={
            <SessionWrapper>
              <Suspense
                fallback={
                  <MainLayout maxWidth={'none'}>
                    <EventPortalLoadingView />
                  </MainLayout>
                }
              >
                <LazyEventPortalRoute />
              </Suspense>
            </SessionWrapper>
          }
        />
        <Route
          path='invoice/event/:id'
          element={
            <SessionWrapper>
              <Suspense fallback={null}>
                <LazyEventRoute />
              </Suspense>
            </SessionWrapper>
          }
        />
        <Route
          path='invoice/:id'
          element={
            <SessionWrapper>
              <Suspense fallback={null}>
                <LazyInvoiceRoute />
              </Suspense>
            </SessionWrapper>
          }
        />
        <Route path='invoice/' element={<NotFoundView />} />
        <Route
          path='catering/locations/discover'
          element={<LazyLocationPickerView />}
        />
        <Route
          path='catering/locations/:mgmtGuid'
          element={
            <Suspense fallback={null}>
              <LazyLocationPickerView />
            </Suspense>
          }
        />
      </Routes>
    </BrowserRouter>
  )
}

export default Router
