import React from 'react'
import { ErrorPage500 } from '@toasttab/buffet-pui-error-pages'
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary'
import { BodyBackgroundColor } from '../shared/BodyBackgroundColor'
import { MainLayout, MainLayoutProps } from './MainLayout'

const Main: React.FC<React.PropsWithChildren<MainLayoutProps>> = ({
  maxWidth = '2xl',
  children
}) => {
  return (
    <>
      <BodyBackgroundColor colorVar={'color-gray-30'} />
      <ErrorBoundary fallback={<ErrorPage500 />}>
        <MainLayout maxWidth={maxWidth}>{children}</MainLayout>
      </ErrorBoundary>
    </>
  )
}

export default Main
